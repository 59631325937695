<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">

        <b-row>

          <b-col cols="12" md="9" lg="9">
            <div class="d-flex">
              <feather-icon icon="GridIcon" size="16"/>
              <h5 class="mb-0 mt-0 ml-50">Genel Bilgiler</h5>
            </div>

            <b-row class="mt-1 mb-1">

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Kısa İsim" rules="required">
                  <b-form-group label="* Kısa İsim">
                    <b-form-input trim placeholder="Kısa İsim" v-model="dataInfo.shortTitle" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Ünvan" rules="required">
                  <b-form-group label="* Ünvan">
                    <b-form-input trim placeholder="Ünvan" v-model="dataInfo.title" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

            <div class="d-flex">
              <feather-icon icon="PhoneIcon" size="16"/>
              <h5 class="mb-0 ml-50">İletişim Bilgileri</h5>
            </div>

            <b-row class="mt-1 mb-1">

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Telefon Numarası">
                  <b-form-group label="Telefon Numarası">
                    <b-form-input trim placeholder="Telefon Numarası" v-model="dataInfo.phoneNumber" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Telefon Numarası 2">
                  <b-form-group label="Telefon Numarası 2">
                    <b-form-input trim placeholder="Telefon Numarası 2" v-model="dataInfo.phoneNumber2" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="E Posta">
                  <b-form-group label="E Posta">
                    <b-form-input trim placeholder="E Posta" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="E Posta 2">
                  <b-form-group label="E Posta 2">
                    <b-form-input trim placeholder="E Posta 2" v-model="dataInfo.email2" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="WebSite">
                  <b-form-group label="WebSite">
                    <b-form-input trim placeholder="WebSite" v-model="dataInfo.domainUrl" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <feather-icon icon="BookIcon" size="16"/>
              <h5 class="mb-0 ml-50">Ticari Bilgiler</h5>
            </div>

            <b-row class="mt-1 mb-1">

              <b-col cols="6" md="6" lg="6">
                <validation-provider #default="validationContext" name="Vergi Dairesi">
                  <b-form-group label="Vergi Dairesi">
                    <b-form-input trim placeholder="Vergi Dairesi" v-model="dataInfo.taxOffice" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="6" lg="6">
                <validation-provider #default="validationContext" name="Vergi Numarası">
                  <b-form-group label="Vergi Numarası">
                    <b-form-input trim placeholder="Vergi Numarası" v-model="dataInfo.taxNumber" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>


            </b-row>

            <div class="d-flex">
              <feather-icon icon="MapIcon" size="16"/>
              <h5 class="mb-0 ml-50">Adres Bilgileri</h5>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Ülke" rules="required">
                  <b-form-group label="* Ülke" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.countryId" :options="countryOptions" :reduce="val => val.value" :clearable="false" v-on:input="getCities"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Şehir">
                  <b-form-group label="Şehir" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.cityId" :options="cityOptions" :reduce="val => val.value" :clearable="true" v-on:input="getTowns"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="İlçe">
                  <b-form-group label="İlçe" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.townId" :options="townOptions" :reduce="val => val.value" :clearable="true" v-on:input="getDistrictVillages"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Mahalle">
                  <b-form-group label="Mahalle" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.districtId" :options="districtOptions" :reduce="val => val.value" :clearable="true"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-row>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Açık Adres">
                      <b-form-group label="Açık Adres">
                        <b-form-textarea trim placeholder="Açık Adres" v-model="dataInfo.address" :state="getValidationState(validationContext)" rows="4"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-row>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Latitude">
                      <b-form-group label="Latitude">
                        <b-form-input trim placeholder="Latitude" v-model="dataInfo.latitude" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="12" lg="12">
                    <validation-provider #default="validationContext" name="Longitude">
                      <b-form-group label="Longitude">
                        <b-form-input trim placeholder="Longitude" v-model="dataInfo.longitude" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <div class="d-flex">
              <feather-icon icon="WatchIcon" size="16"/>
              <h5 class="mb-0 ml-50">Çalışma Bilgileri</h5>
            </div>

            <b-row class="mt-1 mb-3">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Başlangıç Saati" rules="required">
                  <b-form-group label="* Başlangıç Saati" :state="getValidationState(validationContext)">
                    <b-form-timepicker v-model="dataInfo.workingStartTime" class="mb-2"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Bitiş Saati" rules="required">
                  <b-form-group label="* Bitiş Saati" :state="getValidationState(validationContext)">
                    <b-form-timepicker v-model="dataInfo.workingEndTime" local="tr" class="mb-2"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-form-checkbox class="custom-control-success mr-5 mb-2" v-model="dataInfo.monday" switch inline>Pazartesi</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.tuesday" switch inline>Salı</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.wednesday" switch inline>Çarşamba</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.thursday" switch inline>Perşembe</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.friday" switch inline>Cuma</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.saturday" switch inline>Cumartesi</b-form-checkbox>
                <b-form-checkbox class="custom-control-success mr-5" v-model="dataInfo.sunday" switch inline>Pazar</b-form-checkbox>
              </b-col>
            </b-row>

            <div class="d-flex">
              <feather-icon icon="InfoIcon" size="16"/>
              <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
            </div>

            <b-row class="mt-1">

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Durum" rules="required">
                  <b-form-group label=" Durum" label-for="isActive" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Deneme Süresi Hatırlatma">
                  <b-form-group label="Deneme Süresi Hatırlatma" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.trialReminderId" :options="trialReminderOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="12" lg="12">
                <validation-provider #default="validationContext" name="Açıklama">
                  <b-form-group label="Açıklama">
                    <b-form-input trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>


            </b-row>

          </b-col>

          <b-col cols="12" md="3" lg="3" class="d-flex flex-wrap justify-content-center align-content-center">
            <b-avatar
                ref="previewEl"
                :src="(dataInfo.imageBase64 ? dataInfo.imageBase64 : getApiFile(dataInfo.imageUrl))"
                button
                variant="primary"
                size="90px"
                @click="$refs.refInputEl.click()">
              <feather-icon icon="BookIcon" size="30" v-if="dataInfo.imageBase64 === null && dataInfo.imageUrl === ''"/>
            </b-avatar>
            <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
          </b-col>

        </b-row>

        <action-buttons :back-route="'organization-corporation-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox, BFormTimepicker
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/organization/corporation/store"
import definitionModule from "@/views/system/definition/store"
import addressModule from "@/store/common/address"
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BFormTimepicker,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,

    ActionButtons,
    Overlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_ADDRESS_MODULE_NAME = 'store-address'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_ADDRESS_MODULE_NAME, addressModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_ADDRESS_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      shortTitle: '',
      title: '',
      countryId: null,
      cityId: null,
      townId: null,
      districtId: null,
      villageId: null,
      phoneNumber: null,
      phoneNumber2: null,
      email: null,
      email2: null,
      address: null,
      taxOffice: null,
      taxNumber: null,
      imageUrl: null,
      imageBase64: null,
      domainUrl: null,
      description: null,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: false,
      workingStartTime: null,
      workingEndTime: null,
      trialReminderId: null,
      isActive: true
    })

    const countryOptions = ref([])
    const cityOptions = ref([])
    const townOptions = ref([])
    const districtOptions = ref([])
    const villageOptions = ref([])

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'organization-corporation-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const getCities = (val) => {
      cityOptions.value = []
      townOptions.value = []
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/cityList', {country: val}).then(response => {
          response.data.data.forEach((value, index) => {
            cityOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.cityId = null
        dataInfo.value.townId = null
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getTowns = (val) => {
      townOptions.value = []
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/townList', {city: val}).then(response => {
          response.data.data.forEach((value, index) => {
            townOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.townId = null
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getDistrictVillages = (val) => {
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/districtList', {town: val}).then(response => {
          response.data.data.forEach((value, index) => {
            districtOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })

      } else {
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.imageBase64 = base64
    })

    const trialReminderOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'TRIAL_REMINDER_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        trialReminderOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.trialReminderId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-address/countryList').then(response => {
      response.data.data.forEach((value, index) => {
        countryOptions.value.push({label: value.name, value: value.id})
      });

      if (router.currentRoute.params.id === undefined) {
        getCities(dataInfo.value.countryId)
      }

    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      //get customer
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data

        getCities(dataInfo.value.countryId)

        if (dataInfo.value.cityId > 0) {
          getTowns(dataInfo.value.cityId)
        }
        if (dataInfo.value.townId > 0) {
          getDistrictVillages(dataInfo.value.townId)
        }

        if (dataInfo.value.workingStartTime !== null) {
          dataInfo.value.workingStartTime = dataInfo.value.workingStartTime.split('T')[1].replace('.0000000', '')
          dataInfo.value.workingEndTime = dataInfo.value.workingEndTime.split('T')[1].replace('.0000000', '')
        }

      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'organization-corporation-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,
      countryOptions,
      cityOptions,
      townOptions,
      districtOptions,
      villageOptions,
      trialReminderOptions,

      previewEl,
      refInputEl,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,

      getCities,
      getTowns,
      getDistrictVillages,
      inputImageRenderer,
      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>